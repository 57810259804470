import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ChakraProvider } from "@chakra-ui/react";

localStorage.setItem("chakra-ui-color-mode", "dark");

const config = {
    apiKey: "AIzaSyCOsCBfR7Di1THUxclc-jjOdbBwsJ5TKt0",
    authDomain: "humble-stacker.firebaseapp.com",
    projectId: "humble-stacker",
    storageBucket: "humble-stacker.appspot.com",
    messagingSenderId: "485982934709",
    appId: "1:485982934709:web:60339043222a6855aed0fc",
};

const app = initializeApp(config);
getAnalytics(app);

const root = document.getElementById("root");

ReactDOM.createRoot(root).render(
    <ChakraProvider>
        <App />
    </ChakraProvider>
);
