import { Text, Flex } from "@chakra-ui/react";

export default function Footer() {
    return (
        <Flex
            wrap="wrap"
            position="fixed"
            bottom="16px"
            justify="flex-end"
            left="16px"
            right="16px"
        >
            <Text fontSize="xs" textColor="gray">
                2025 © Satoshi Limited and Humblestacker Limited. All rights
                reserved.
            </Text>
        </Flex>
    );
}
