import React, { useState, useEffect } from "react";
import {
    Box,
    AbsoluteCenter,
    Stack,
    Text,
    Link,
    Button,
    Flex,
} from "@chakra-ui/react";

export default function Home() {
    return (
        <Box position="relative" height="100vh">
            <AbsoluteCenter width="100vw" textAlign="center">
                <Stack gap={8} align="center">
                    <Text className="title1 future">HUMBLESTACKER</Text>
                    <Text className="title1 cursive">
                        we are a <span className="orange">bitcoin</span>{" "}
                        infrastructure company
                    </Text>
                    <Flex>
                        <Link href="mailto:hello@humblestacker.com">
                            <Button>Contact us</Button>
                        </Link>
                    </Flex>
                </Stack>
            </AbsoluteCenter>
        </Box>
    );
}
